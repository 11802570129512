import React, { useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Paragraph, ResponsiveContext, Stack } from 'grommet';
import { isEqual, get, head } from '../../lib/nodash';

import findImage from '../../lib/findImage';
import {
  getMaxPrice,
  extractVariantId,
  getProductPath,
} from '../../lib/product';
import formatCurrency from '../../lib/formatCurrency';
import deNodify from '../../lib/deNodify';
import calculateCartDiscounts from '../../lib/cart/calculateCartDiscounts';
import { SUGGESTED_PRODUCT_ADDED } from '../../lib/analytics/segmentActions';

import SmartLink from '../SmartLink';
import ImgixImage from '../ImgixImage';
import ProductAddToCartForm from './ProductAddToCartForm';
import IconCheck from '../Icons/IconCheck';
import DiscountedPriceLabel from '../Product/DiscountedPriceLabel';
import useHover from '../useHover';

const RecommendedToolCard = ({
  productData,
  supplyDiscount,
  size = 'medium',
  fill = false,
  suggestedQuantity,
  suggestedQuantityFulfilled,
  hoverImageMatcher = '_hover',
  imageMatcher = '_grid',
}) => {
  const variantId = extractVariantId(
    get('id', head(deNodify(productData.variants)))
  );
  const gridImageUrl = findImage(productData.images, imageMatcher);
  const hoverImage = findImage(productData.images, hoverImageMatcher);
  const isDecimal = (num) => num % 1 !== 0;
  const isPrimer = isEqual(get('productType', productData), 'Primer');
  const isDiscounted = supplyDiscount > 0;

  const viewportSize = useContext(ResponsiveContext);
  const isMobile = viewportSize === 'small';

  const [hoverRef, isHovered] = useHover();

  return (
    <Box
      ref={hoverRef}
      className={`product-card product-card--tool`}
      fill={fill}
    >
      <Stack fill>
        <Box
          justify="center"
          fill
          flex={{ grow: 1 }}
          height={{ min: isMobile ? 'none' : '42em' }}
          gap="large"
          background="#F0F0F0"
        >
          {suggestedQuantityFulfilled && (
            <Box
              style={{
                position: 'absolute',
                top: '16px',
                right: '16px',
                zIndex: 250,
              }}
            >
              <IconCheck color="black" size="16px" />
            </Box>
          )}
          <Box pad={size === 'small' ? '15px 15px 100px' : '25px 25px 0'}>
            <Box
              justify="center"
              align="center"
              margin="auto"
              className="product-card__image-container"
              height={{ max: '270px' }}
              width={size === 'small' ? { max: '100px' } : 'auto'}
              style={size === 'small' ? { maxWidth: '100px' } : {}}
            >
              <ImgixImage
                options={{ w: 800, q: 80 }}
                className="product-card__image"
                src={gridImageUrl}
                alt={productData.title}
                width="100%"
                fit="contain"
                style={{
                  opacity: isHovered ? 0 : 1,
                  transition: 'opacity 0.2s',
                }}
              />
            </Box>
            <Box className="product-card__details">
              <Heading
                textAlign="center"
                margin={{ bottom: 'none' }}
                level={5}
                className="product-card__title option-type"
              >
                {productData.title}
              </Heading>
              <Paragraph
                textAlign="center"
                className="product-card__description type"
              >
                {productData.description}
              </Paragraph>
              <Box
                direction="column"
                justify="center"
                align="center"
                gap="small"
              >
                <Paragraph className="product-card__price option-type">
                  {isPrimer ? (
                    <>
                      {isDiscounted
                        ? formatCurrency(
                            calculateCartDiscounts.getDiscountedItemPrice(
                              getMaxPrice(productData.priceRange)
                            )
                          )
                        : formatCurrency(getMaxPrice(productData.priceRange))}
                    </>
                  ) : (
                    <>
                      {isDiscounted
                        ? formatCurrency(
                            getMaxPrice(productData.priceRange) *
                              (1 - supplyDiscount),
                            'USD',
                            isDecimal(
                              getMaxPrice(productData.priceRange) *
                                (1 - supplyDiscount)
                            )
                              ? 2
                              : 0
                          )
                        : formatCurrency(getMaxPrice(productData.priceRange))}
                    </>
                  )}
                </Paragraph>
                {isDiscounted && (
                  <DiscountedPriceLabel
                    size="12px"
                    fullPrice={getMaxPrice(productData.priceRange)}
                  />
                )}
              </Box>
            </Box>
          </Box>
          {isMobile && (
            <ProductAddToCartForm
              product={productData}
              variantId={variantId}
              suggestedQuantity={suggestedQuantity}
              handleQuantityChange={(y) => y}
              direction="row-responsive"
              noVerticalBorders={true}
              buttonLabel={
                isMobile
                  ? `Add ${suggestedQuantity === 0 ? '' : suggestedQuantity}`
                  : 'Add'
              }
              size="medium"
              showQuantitySelect={!isMobile}
              trackingEvent={SUGGESTED_PRODUCT_ADDED}
            />
          )}
        </Box>

        {!isMobile && (
          <Box
            fill
            border={{ side: 'all', color: 'black', size: 'small' }}
            style={{
              borderBottom: 'none',
              opacity: isHovered ? 1 : 0,
              transition: 'opacity 0.2s',
            }}
          >
            <SmartLink
              to={getProductPath(productData)}
              className="product-card--tool__hover"
              style={{ height: '100%' }}
            >
              <Box justify="between" fill className="link-inner">
                <Box
                  height={{ max: '330px', min: isMobile ? 'none' : '200px' }}
                  fill="horizontal"
                >
                  <ImgixImage
                    className="product-card--tool__hover-image"
                    src={hoverImage}
                    alt={`${productData.title} grid image`}
                    options={{ w: 800, q: 80 }}
                    width="100%"
                    fit="cover"
                  />
                </Box>
                <Box className="cart-button-container">
                  <ProductAddToCartForm
                    product={productData}
                    variantId={variantId}
                    suggestedQuantity={suggestedQuantity}
                    className="c-card__bar"
                    handleQuantityChange={(y) => y}
                    direction="row-responsive"
                    noVerticalBorders={true}
                    buttonLabel={
                      isMobile
                        ? `Add ${
                            suggestedQuantity === 0 ? '' : suggestedQuantity
                          }`
                        : 'Add'
                    }
                    size="medium"
                    showQuantitySelect={!isMobile}
                    trackingEvent={SUGGESTED_PRODUCT_ADDED}
                  />
                </Box>
              </Box>
            </SmartLink>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default memo(RecommendedToolCard);

RecommendedToolCard.propTypes = {
  size: PropTypes.string,
  productData: PropTypes.object,
  supplyDiscount: PropTypes.number,
  plain: PropTypes.bool,
  fill: PropTypes.bool,
  suggestedQuantity: PropTypes.number,
  imageMatcher: PropTypes.string,
  hoverImageMatcher: PropTypes.string,
  suggestedQuantityFulfilled: PropTypes.bool,
};
