import React, { useContext, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { graphql, useStaticQuery } from 'gatsby';
import { Box, ResponsiveContext, Button, Heading, Text, Grid } from 'grommet';
import { FormNextLink } from 'grommet-icons';

import {
  isEqual,
  get,
  keys,
  find,
  reduce,
  flatten,
  map,
  mapKeys,
} from '../../lib/nodash';

import extractGid from '../../lib/extractGid';
import calculateCartDiscounts from '../../lib/cart/calculateCartDiscounts';
import ProductCardSimple from '../Product/ProductCardSimple';
import CalcProductSuggestion from './CalcProductSuggestion';
import CalcProductQuantityList from './CalcProductQuantityList';
import DiscountedPriceLabel from '../Product/DiscountedPriceLabel';
import { StoryblokComponent } from 'gatsby-source-storyblok';
// import Loading from '../Loading';

import { totalSquareFootage } from '../../lib/calculations/paintCalculations';
import numberWithCommas from '../../lib/numberWithCommas';
import defaultPaintProduct from '../../lib/defaultPaintProduct';
import deNodify from '../../lib/deNodify';
import selectGallonsForCalculatedProducts from '../../state/calculator/selectGallonsForCalculatedProducts';
import makeSelectVariantQuantitiesForGallons from '../../state/calculator/makeSelectVariantQuantitiesForGallons';
import selectGallonsToPaintDiscount from '../../state/calculator/selectGallonsToPaintDiscount';
import suggestionsTotal from '../../lib/calculations/suggestionsTotal';
import generateCartItemsForProducts from '../../lib/cart/generateCartItemsForProducts';
import { overrideGallons } from '../../state/calculator/calculatorSlice';
import { useStoryblokState } from '../../lib/storyblok';
import discountSavingsMessage from '../../lib/cart/discountSavingsMessage';
import calculateSavedOnSupplies from '../../lib/cart/calculateSavedOnSupplies';
import calculateSavedOnPaint from '../../lib/cart/calculateSavedOnPaint';

const CalcResultsMainProducts = ({
  paintData,
  productsData,
  preventAddToCartMessage,
  activeDiscounts,
  industryReferralCode,
  cartDiscountCode,
  recommendedKitQuantity = 1,
  paintCollections,
  onBrowse,
  loading,
}) => {
  const dispatch = useDispatch();
  const size = useContext(ResponsiveContext);
  // Selectors
  const sqFt = useSelector((state) => state.calculator.sqFt);
  const skippedCalc = useSelector((state) => state.calculator.skippedCalc);
  const people = useSelector((state) => state.calculator.calcVariables.people);
  const gallonsToPaintDiscount = useSelector(selectGallonsToPaintDiscount);
  const calculatedProducts = useSelector(
    (state) => state.calculator.calculatedProducts
  );
  const gallons = useSelector(selectGallonsForCalculatedProducts);
  const selectVariantQuantitiesForGallons = useMemo(
    makeSelectVariantQuantitiesForGallons,
    [gallons]
  );

  const variantsForGallonsQuantities = useSelector((state) =>
    selectVariantQuantitiesForGallons(state, {
      paintVariants: flatten(
        map((x) => deNodify(get('variants', x)), paintData)
      ),
      gallonMap: gallons,
    })
  );

  const quantities = mapKeys(
    (x) => extractGid(x),
    variantsForGallonsQuantities
  );

  const potentialCartItems = generateCartItemsForProducts(
    productsData,
    gallons
  );
  const savedOnSupplies = calculateSavedOnSupplies(potentialCartItems);
  const savedOnPaint = calculateSavedOnPaint(potentialCartItems);
  const totalPrice =
    suggestionsTotal({
      quantities: variantsForGallonsQuantities,
      productsData: productsData,
    }) * 100;

  const totalDiscountedPrice =
    calculateCartDiscounts.getTotalDiscountedPrice(
      potentialCartItems,
      totalPrice,
      activeDiscounts
    ) * 100;

  const defaultDiscountMessage = discountSavingsMessage({
    // orderDiscounts,
    discountCode: cartDiscountCode,
    industryReferralCode,
    savedOnPaint,
    savedOnSupplies,
    lineItems: potentialCartItems,
  });

  const isMobile = size === 'small';
  const sqFtTotal = totalSquareFootage(sqFt);

  const handleQuantityChange = (values) => {
    dispatch(
      overrideGallons({
        values,
        cartItems: potentialCartItems,
        industryReferralCode,
      })
    );
  };

  const noCalcs = calculatedProducts.length === 0 || skippedCalc;
  const paintKeys = keys(quantities);

  const paintGallonsString = reduce(
    (mem, x) => {
      const quant = quantities[x];
      const i = paintKeys.indexOf(x);
      const isGeneric = isEqual(x, '_generic');
      const paint = find({ id: x }, paintData);
      const separator =
        i === 0
          ? ''
          : paintKeys.length > 1
            ? isEqual(i + 1, paintKeys.length)
              ? `${paintKeys.length > 2 ? ',' : ''} and `
              : ', '
            : '';
      return `${mem}${separator}${quant} ${
        quant > 1 ? 'gallons' : 'gallon'
      } of ${isGeneric ? 'Backdrop Paint' : get('title', paint)}`;
    },
    '',
    paintKeys
  );

  const isPaintDiscounted =
    cartDiscountCode || (totalPrice > 0 && totalDiscountedPrice > totalPrice);

  const standardDiscountMessage =
    cartDiscountCode && !industryReferralCode
      ? "You're saving with a discount code!"
      : defaultDiscountMessage;
  const industryDiscountMessage =
    "You're saving 20% with your Industry pricing.";

  const discountExplanation = industryReferralCode
    ? industryDiscountMessage
    : standardDiscountMessage;

  const discountPromptExplanation = `You're only ${gallonsToPaintDiscount} away from saving $20+`;

  // Storyblok Content
  const { summary } = useStaticQuery(graphql`
    query CalcResultsSummaryContent {
      summary: storyblokEntry(
        full_slug: { eq: "pages/calculator/results/summary" }
      ) {
        id
        name
        full_slug
        content
        field_title_string
        internalId
      }
    }
  `);

  const summaryBlok = useStoryblokState(summary);
  const summaryComponents = summaryBlok.content.body.map((blok) => (
    <StoryblokComponent
      blok={blok}
      key={blok._uid}
      pad="none"
      templateVars={{
        sqFt: numberWithCommas(sqFtTotal),
        gallons: paintGallonsString,
        essentialsKitCount:
          recommendedKitQuantity === 1 ? 'one' : recommendedKitQuantity,
      }}
    />
  ));

  const summaryTitle = summaryBlok.content.body.find(
    (x) => x.component === 'TextContentModule'
  )?.title;

  return (
    <Box
      direction="row-responsive"
      justify="between"
      fill="horizontal"
      gap="large"
    >
      <Box className="cresults__text-block" flex={true}>
        <Box pad={isMobile ? { horizontal: 'large' } : 'none'}>
          {noCalcs && (
            <Heading
              level={3}
              size="small"
              margin={{ bottom: 'small', top: 'none' }}
            >
              Let&apos;s Review
            </Heading>
          )}
          {isMobile ? (
            <Heading level={3}>{summaryTitle}</Heading>
          ) : (
            <Box>{summaryComponents}</Box>
          )}
        </Box>

        {isMobile && (
          <Box overflow="auto" pad="large">
            <div style={{ width: 'fit-content' }}>
              <Box direction="row" gap="large">
                {paintData && isEqual(paintData.length, 0) ? (
                  <Box>
                    <ProductCardSimple
                      fill
                      background="light-1"
                      title={defaultPaintProduct.title}
                      imageUrl={
                        defaultPaintProduct.images.edges[0].node.originalSrc
                      }
                      onClick={() => onBrowse({ continueTo: 'results' })}
                      pad={{ top: 'medium', horizontal: 'medium' }}
                      border={[
                        { side: 'top', size: 'small' },
                        { side: 'vertical', size: 'small' },
                      ]}
                    />
                    <Button
                      secondary
                      label="Select Your Color"
                      icon={<FormNextLink />}
                      reverse
                      size="small"
                      onClick={() => onBrowse({ continueTo: 'results' })}
                    />
                  </Box>
                ) : (
                  paintData.map((x) => {
                    const paintId = extractGid(x.id);
                    return (
                      <Box key={paintId} width="230px">
                        <CalcProductSuggestion
                          key={paintId}
                          productData={x}
                          suggestedGallons={gallons[paintId]}
                          preventAddToCartMessage={preventAddToCartMessage}
                          onQuantityChange={handleQuantityChange}
                          paintCollections={paintCollections}
                        />
                      </Box>
                    );
                  })
                )}
              </Box>
            </div>
          </Box>
        )}

        <Box
          className="cresults__add-all"
          pad={isMobile ? 'large' : undefined}
          border={
            isMobile
              ? { side: 'horizontal', size: 'small', color: 'black' }
              : undefined
          }
          gap="small"
        >
          {!loading && (
            <Box gap="small" direction="column" className="outer">
              <Box pad={{ vertical: 'small' }} className="inner-padded">
                {totalPrice > 0 && (
                  <Box
                    flex={false}
                    direction="row"
                    align="center"
                    gap="xsmall"
                    className="text-container"
                  >
                    <Text size="large">$</Text>
                    <Text size="xxlarge" weight={500}>
                      {numberWithCommas(
                        (totalDiscountedPrice
                          ? totalDiscountedPrice / 100
                          : 0
                        ).toFixed(2)
                      )}
                    </Text>
                  </Box>
                )}
                <Box>
                  {totalPrice > 0 && (
                    <DiscountedPriceLabel
                      isDiscounted={isPaintDiscounted}
                      fullPrice={totalPrice / 100}
                      size="small"
                      explanation={
                        isPaintDiscounted
                          ? discountExplanation
                          : discountPromptExplanation
                      }
                      prompt={
                        gallonsToPaintDiscount > 0 && defaultDiscountMessage
                      }
                    />
                  )}
                </Box>
              </Box>
              <CalcProductQuantityList
                gallons={gallons}
                quantities={quantities}
                itemData={paintData}
                people={people}
              />
            </Box>
          )}
        </Box>
      </Box>
      {!isMobile && (
        <Box width="calc(66vw - 50px)">
          <Grid
            flex={true}
            columns={{ size: 'auto', count: 2 }}
            className="cresults__cards-block"
            gap="large"
          >
            {paintData && isEqual(paintData.length, 0) ? (
              <Box flex={true}>
                <ProductCardSimple
                  fill
                  background="light-1"
                  title={defaultPaintProduct.title}
                  imageUrl={
                    defaultPaintProduct.images.edges[0].node.originalSrc
                  }
                  onClick={() => onBrowse({ continueTo: 'results' })}
                  pad={{ top: 'medium', horizontal: 'medium' }}
                  border={[
                    { side: 'top', size: 'small' },
                    { side: 'vertical', size: 'small' },
                  ]}
                />
                <Button
                  secondary
                  label="Select Your Color"
                  icon={<FormNextLink />}
                  reverse
                  size="small"
                  onClick={() => onBrowse({ continueTo: 'results' })}
                />
              </Box>
            ) : (
              paintData.map((x) => {
                const paintId = extractGid(x.id);
                return (
                  <Box key={paintId} width={{ min: '300px' }}>
                    <CalcProductSuggestion
                      key={paintId}
                      productData={x}
                      suggestedGallons={gallons[paintId]}
                      preventAddToCartMessage={preventAddToCartMessage}
                      onQuantityChange={handleQuantityChange}
                      paintCollections={paintCollections}
                    />
                  </Box>
                );
              })
            )}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

CalcResultsMainProducts.propTypes = {
  activeDiscounts: PropTypes.array,
  preventAddToCartMessage: PropTypes.string,
  onBackToForm: PropTypes.func,
  paintData: PropTypes.array,
  productsData: PropTypes.object,
  people: PropTypes.number,
  genericImage: PropTypes.string,
  paintCollections: PropTypes.array,
  industryReferralCode: PropTypes.string,
  cartDiscountCode: PropTypes.string,
  cartItems: PropTypes.array,
  recommendedKitQuantity: PropTypes.number,
  loading: PropTypes.bool,
  onBrowse: PropTypes.func,
};

export default memo(CalcResultsMainProducts);
