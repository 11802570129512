import React, { memo } from 'react';
import { DebounceInput } from 'react-debounce-input';
import PropTypes from 'prop-types';
import { Button, Stack, Box } from 'grommet';
import { isNil } from '../../lib/nodash';
import styled from 'styled-components';

import IconMinus from '../Icons/IconMinus';
import IconPlus from '../Icons/IconPlus';
import Loading from '../Loading';

const StyledButton = styled(Button)`
  padding: 10px;
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  svg {
    vertical-align: middle;
  }
`;

const InputIncDec = ({
  value,
  onChange,
  disabled = false,
  disableInputEntry = false,
  min = 0,
  max,
  adjusting,
}) => {
  const canIncrease = isNil(max) ? true : value < max;
  const canDecrease = value > min;
  return (
    <Box
      width="100px"
      height="30px"
      direction="row"
      className="cartcard__qty"
      justify="center"
      align="center"
    >
      <StyledButton
        icon={<IconMinus size="small" color="black" />}
        title="Decrease"
        disabled={disabled || !canDecrease}
        onClick={() => onChange(value - 1)}
      />
      <Stack fill>
        <Box fill>
          <DebounceInput
            debounceTimeout={300}
            className="cartcard__qty-input"
            type="number"
            value={value}
            min="0"
            readOnly={disableInputEntry}
            aria-label="Item quantity"
            onChange={(e) => {
              const val = parseInt(e.target.value);
              onChange(val < min ? min : val);
            }}
            disabled={disabled}
            style={{
              opacity: adjusting ? 0 : disabled ? 0.5 : 1,
              backgroundColor: '#f6f6f6',
              padding: '0 0 1px',
              border: 0,
              margin: '0 auto',
              display: 'block',
              height: '100%',
              textAlign: 'center',
              fontSize: '15px',
              width: '100%',
              color: '#000',
              appearance: 'none',
              cursor: disableInputEntry ? 'default' : 'text',
            }}
          />
        </Box>
        {adjusting && (
          <Box fill align="center" justify="center">
            <Loading />
          </Box>
        )}
      </Stack>

      <StyledButton
        icon={<IconPlus size="small" color="black" />}
        title="Increase"
        disabled={disabled || !canIncrease}
        onClick={() => onChange(value + 1)}
      />
    </Box>
  );
};

export default memo(InputIncDec);

InputIncDec.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  disableInputEntry: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  adjusting: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};
