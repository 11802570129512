import { createSelector } from 'reselect';
import { keys, reduce, isEqual, filter, get } from '../../lib/nodash';
import calculateVariantsForGallons from '../../lib/calculations/calculateVariantsForGallons';
import deNodify from '../../lib/deNodify';
import extractGid from '../../lib/extractGid';

const makeSelectVariantQuantitiesForGallons = () =>
  createSelector(
    (state) => state.calculator,
    (_, params) => params,
    (calculator, { paintVariants, gallonMap }) => {
      return reduce(
        (mem, x) => {
          const qty = calculateVariantsForGallons(
            filter(
              (v) => isEqual(x, extractGid(get('product.id', v))),
              deNodify(paintVariants)
            ),
            gallonMap[x]
          );
          return { ...mem, ...qty };
        },
        {},
        keys(gallonMap)
      );
    }
  );

export default makeSelectVariantQuantitiesForGallons;
