import findImageForProduct from '../findImageForProduct';
import extractGid from '../extractGid';
import deNodify from '../deNodify';
import calculateVariantsForGallons from '../calculations/calculateVariantsForGallons';
import { get, map, reduce, isEqual, find, keys, compact } from '../nodash';

const generateCartItemsForProducts = (productsData, gallons) => {
  const kitId = get('id', get('essentialsKit', productsData));
  return reduce(
    (mem, x) => {
      const parsedId = extractGid(x);
      const dataSource = isEqual(parsedId, extractGid(kitId))
        ? [
            {
              ...get('essentialsKit', productsData),
              id: extractGid(get('essentialsKit.id', productsData)),
            },
          ]
        : get('paint', productsData);

      const product = find(
        (p) => isEqual(extractGid(get('id', p)), parsedId),
        dataSource
      ) || {
        id: '_generic',
        price: '59.00',
        priceV2: {
          amount: '59.00',
        },
        priceRange: {
          maxVariantPrice: '59.00',
        },
        title: 'Backdrop Paint',
        productType: 'Interior Standard',
      };

      const variants = deNodify(get('variants', product));
      const variantQuantityMap = calculateVariantsForGallons(
        variants,
        gallons[x]
      );

      const variantsForGallons = map((x) => {
        const variant = find({ id: x }, variants);
        const output = {
          id: x,
          properties: {},
          quantity: variantQuantityMap[x],
          variant_id: x,
          title: `${get('title', product)} - ${get('title', variant)}`,
          price:
            get('priceV2.amount', variant) ||
            get('priceRange.maxVariantPrice', product),
          image: findImageForProduct(
            get('images', product),
            get('productType', product)
          ),
          handle: get('handle', product),
          product_type: get('productType', product),
          product_title: get('title', product),
          product_description: get('description', product),
          variant_title: get('title', variant),
        };
        return output;
      }, keys(variantQuantityMap));

      return [...mem, ...variantsForGallons];
    },
    [],
    compact(keys(gallons))
  );
};

export default generateCartItemsForProducts;
