import React, { useContext, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, ResponsiveContext, Paragraph } from 'grommet';

import { get, includes, some } from '../../lib/nodash';
import findImage from '../../lib/findImage';
import {
  findOneGalVariant,
  findFiveGalVariant,
  isFiveGallonQty,
  getProductPath,
  findMetafieldValue,
} from '../../lib/product';
import extractGid from '../../lib/extractGid';
import sanitizeHtml from '../../lib/sanitizeHtml';
import ImagePlaceholder from '../ImagePlaceholder';
import ImgixImage from '../ImgixImage';
import SmartLink from '../SmartLink';
import ProductAddToCartForm from '../Product/ProductAddToCartForm';
import Loading from '../Loading';

const emptyProduct = {
  handle: '',
  title: '',
  variants: {
    edges: [{}],
  },
  images: { edges: [{}] },
};

const CalcProductSuggestion = ({
  productData = emptyProduct,
  paintCollections = [],
  loadingProduct,
  suggestedGallons = 1,
  preventAddToCartMessage,
  onQuantityChange,
  largeImage,
  hideDescription = false,
  imageMax = '100px',
  size = 'medium',
}) => {
  const viewportSize = useContext(ResponsiveContext);
  const isMobile = viewportSize === 'small';

  const product = productData;
  const fiveGal = isFiveGallonQty(product);

  const productDescription = loadingProduct
    ? null
    : sanitizeHtml(get('descriptionHtml', product));
  const productVariant = fiveGal
    ? findFiveGalVariant(get('variants', product))
    : findOneGalVariant(get('variants', product)) || {};
  const variantId = extractGid(productVariant.id);

  const paintCollection = useMemo(
    () =>
      paintCollections.find(
        (c) => c.products.map((p) => p.shopifyId).indexOf(product.id) > -1
      ),
    [product.id, paintCollections]
  );

  const isKit = product.handle === 'essentials-kit';
  const gridImageUrl = useMemo(
    () =>
      paintCollection
        ? findMetafieldValue('collection_image', paintCollection.metafields)
          ? findMetafieldValue(
              'collection_image',
              paintCollection.metafields
            )[0]?.original_src ||
            findMetafieldValue('collection_image', paintCollection.metafields)
              ?.originalSrc
          : undefined
        : findImage(get('images', product), isKit ? '_hero' : '_grid'),
    [paintCollection, product]
  );

  const paintFinishes = [
    'Standard',
    'Semi-gloss',
    'Semigloss',
    'Interior Standard',
    'Exterior Standard',
    'Interior Semi-Gloss',
    'Exterior Semi-Gloss',
    'Cabinet & Door',
  ];
  const isPaint = some((t) => includes(product.productType, t), paintFinishes);

  return (
    <Box
      className="c-card"
      flex={isMobile ? { grow: 1, shrink: 0 } : true}
      direction="column"
      justify="between"
      background="#F0F0F0"
      pad={largeImage ? undefined : size}
    >
      <Box className="c-card__inner" justify="between" flex={{ grow: 1 }}>
        <Box
          pad={largeImage ? { horizontal: 'medium', top: 'medium' } : undefined}
          gap="small"
          justify="center"
          align="center"
        >
          <Heading
            level={4}
            textAlign="center"
            size={isMobile ? 'small' : size}
            margin={{
              top: size === 'small' ? 'small' : 'none',
              bottom: 'none',
            }}
          >
            <SmartLink
              style={{ fontWeight: 500, textDecoration: 'none' }}
              to={getProductPath(product)}
            >
              {product.title}
            </SmartLink>
          </Heading>
          {isPaint && <p>in {product.productType}</p>}
        </Box>
        <Box pad={{ horizontal: largeImage ? 'medium' : undefined }}>
          {!hideDescription && productDescription && largeImage && (
            <p dangerouslySetInnerHTML={{ __html: productDescription }} />
          )}
        </Box>
        <Box justify="center" align="center">
          <Box
            width={{
              max: largeImage ? '100%' : size === 'small' ? imageMax : '180px',
            }}
            className="c-card__img"
            align="center"
            justify="center"
          >
            {loadingProduct ? (
              <Loading size="large" theme="light" centered dimension="180px" />
            ) : (
              <SmartLink to={getProductPath(product)}>
                {gridImageUrl ? (
                  <ImgixImage
                    fill
                    src={gridImageUrl}
                    alt={product.title}
                    options={{ w: 300 }}
                  />
                ) : (
                  <ImagePlaceholder />
                )}
              </SmartLink>
            )}
          </Box>

          {!hideDescription && productDescription && !largeImage && (
            <>
              {paintCollection ? (
                <Paragraph>
                  {findMetafieldValue(
                    'short_description',
                    paintCollection.metafields
                  )}
                </Paragraph>
              ) : (
                <p dangerouslySetInnerHTML={{ __html: productDescription }} />
              )}
            </>
          )}
        </Box>
        <Box
          justify="stretch"
          pad={
            largeImage ? { horizontal: 'medium', bottom: 'medium' } : undefined
          }
        >
          {!loadingProduct && (
            <>
              <Box margin={{ top: 'small' }}>
                <ProductAddToCartForm
                  product={product}
                  variantId={variantId}
                  suggestedQuantity={suggestedGallons}
                  className="c-card__bar"
                  preventAddToCartMessage={preventAddToCartMessage}
                  onQuantityChange={onQuantityChange}
                  direction={size === 'small' ? 'column' : 'row-responsive'}
                  buttonLabel="Add"
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

CalcProductSuggestion.propTypes = {
  product: PropTypes.object,
  suggestedGallons: PropTypes.number,
  size: PropTypes.string,
  hideDescription: PropTypes.bool,
  preventAddToCartMessage: PropTypes.string,
  onQuantityChange: PropTypes.func,
  discountPer: PropTypes.number,
  discountPercentage: PropTypes.number,
  largeImage: PropTypes.bool,
  imageMax: PropTypes.string,
  loadingProduct: PropTypes.bool,
  productData: PropTypes.object,
  gallonDiscount: PropTypes.object,
  paintCollections: PropTypes.array,
};

export default memo(CalcProductSuggestion);
