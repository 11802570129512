import { createSelector } from 'reselect';
import { calculateRecommendedProductQuantities } from '../../lib/cart/cartRecommendations';

const makeSelectSuggestedQuantitiesForProducts = () =>
  createSelector(
    (state) => state.calculator,
    (_, params) => params,
    (calculator, params) => {
      return calculateRecommendedProductQuantities({
        supplyItems: params.supplyItems,
        givenItems: params.givenItems,
        people: calculator.calcVariables.people,
      });
    }
  );

export default makeSelectSuggestedQuantitiesForProducts;
