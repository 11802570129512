import { createSelector } from 'reselect';
import { reduce, keys, isUndefined } from '../../lib/nodash';
import { calculateGallons } from '../../lib/calculations/paintCalculations';

const selectGallonsForCalculatedProducts = createSelector(
  (state) => state.calculator,
  (calc) =>
    reduce(
      (mem, x) => {
        mem[x] = isUndefined(calc.gallonOverrides[x])
          ? calculateGallons(calc.sqFt[x])
          : calc.gallonOverrides[x];
        return mem;
      },
      {},
      keys(calc.sqFt)
    )
);

export default selectGallonsForCalculatedProducts;
