import { createSelector } from 'reselect';
import { sum, values } from '../../lib/nodash';
import selectGallonsForCalculatedProducts from './selectGallonsForCalculatedProducts';

const selectGallonsToPaintDiscount = createSelector(
  (state) => state.calculator,
  selectGallonsForCalculatedProducts,
  (calc, gallons) => Math.max(4 - sum(values(gallons)), 0)
);

export default selectGallonsToPaintDiscount;
