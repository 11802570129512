import React, { useMemo, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { graphql, useStaticQuery } from 'gatsby';
import { shopifyClient } from '../../gatsby-theme-apollo/client';
import PropTypes from 'prop-types';
import {
  find,
  get,
  map,
  keys,
  isEqual,
  uniqBy,
  isNil,
  reduce,
  merge,
  reject,
  compact,
  head,
} from '../../lib/nodash';
import { Box, Heading, Text, Grid } from 'grommet';
import { Group } from 'grommet-icons';
import extractGid from '../../lib/extractGid';

import { useStoryblokState } from '../../lib/storyblok';
import useMobile from '../useMobile';
import calculateCartDiscounts, {
  PAINT_AND_SUPPLY_DISCOUNT_PERCENTAGE,
} from '../../lib/cart/calculateCartDiscounts';
import defaultPaintProduct from '../../lib/defaultPaintProduct';
import calculateVariantsForGallons from '../../lib/calculations/calculateVariantsForGallons';
import deNodify from '../../lib/deNodify';
import encodeGid from '../../lib/encodeGid';
import { setPeople } from '../../state/calculator/calculatorSlice';
import makeSelectSuggestedQuantitiesForProducts from '../../state/calculator/makeSelectSuggestedQuantitiesForProducts';
import selectGallonsForCalculatedProducts from '../../state/calculator/selectGallonsForCalculatedProducts';
import CalcResultsMainProducts from './CalcResultsMainProducts';
import CalcProductQuantityList from './CalcProductQuantityList';
import InputIncDec from '../Inputs/InputIncDec';
import RecommendedToolCard from '../Product/RecommendedToolCard';
import CartShopCollectionButtons from '../Cart/CartShopCollectionButtons';
import ButtonAddAllToCart from '../ButtonAddAllToCart';
import MarkdownContent from '../MarkdownContent';
import InfoToolTip from '../InfoToolTip';
import Loading from '../Loading';
import ContentSlider from '../ContentSlider';
import { GET_SELECTED_AND_RECOMMENDED_PRODUCTS } from '../../queries/productQueries';
import { GET_CHECKOUT } from '../../queries/cartQueries';

const ShopCollectionButtonsSlide = () => (
  <CartShopCollectionButtons
    pad="medium"
    gap="medium"
    fill
    align="center"
    justify="center"
  />
);

const CalcResults = ({ onBackToForm, onBrowse, paintCollections = [] }) => {
  const dispatch = useDispatch();
  const isMobile = useMobile();
  const referralCode = useSelector((state) =>
    get('industry.member.referral_code', state)
  );

  // Selectors
  const selectSuggestedQuantitiesForProducts = useMemo(
    makeSelectSuggestedQuantitiesForProducts,
    []
  );
  const people = useSelector((state) => {
    return state.calculator.calcVariables.people;
  });
  const gallons = useSelector(selectGallonsForCalculatedProducts);

  const checkoutId = useSelector((state) => state.cart.checkoutId);
  const productIds = useSelector((state) =>
    reject(
      (x) => isEqual(x, defaultPaintProduct.id),
      state.calculator.calculatedProducts
    )
  );

  // TODO: get real values
  const genericImage = '';
  const preventAddToCartMessage = null;

  // Queries
  const { data, loading } = useQuery(GET_SELECTED_AND_RECOMMENDED_PRODUCTS, {
    variables: { productIds: map(encodeGid, productIds) },
    client: shopifyClient,
  });
  const { data: cartData } = useQuery(GET_CHECKOUT, {
    variables: { id: checkoutId },
    client: shopifyClient,
  });

  const paintData = map(
    (x) => ({ ...x, id: extractGid(x.id) }),
    get('paint', data) || []
  );
  const essentialsKitData = get('essentialsKit', data) || {};
  const recommendedToolsData = deNodify(
    get('recommendedTools.products', data) || []
  );

  const cartItems = deNodify(get('node.lineItems', cartData));

  const supplyItems = compact([...recommendedToolsData, essentialsKitData]);

  const paintDataWithQty = map((x) => {
    return {
      ...find(
        (y) => isEqual(extractGid(get('id', y)), extractGid(x)),
        [
          ...(paintData || []),
          { id: '_generic', product_type: 'Interior Standard' },
        ]
      ),
      quantity: gallons[x],
    };
  }, keys(gallons));

  const items = [...paintDataWithQty, ...cartItems];

  const mergedItems = uniqBy(
    'product_id',
    map(
      (x) => ({
        ...x,
        product_id: get('variant.product.id', x)
          ? extractGid(get('variant.product.id', x))
          : get('id', x),
      }),
      items
    )
  );

  const suggestedQuantities = useSelector((state) =>
    selectSuggestedQuantitiesForProducts(state, {
      supplyItems,
      givenItems: mergedItems,
    })
  );

  const relativeQuantities = useSelector((state) =>
    selectSuggestedQuantitiesForProducts(state, {
      supplyItems,
      givenItems: mergedItems,
      relativeToCart: true,
    })
  );

  const activeDiscounts = useMemo(
    () =>
      calculateCartDiscounts.getActiveDiscounts(items, !isNil(referralCode)),
    [mergedItems, referralCode]
  );

  const hasSuppliesDiscount =
    activeDiscounts.indexOf('paint-and-supply-discount') > -1;

  const recommendedKitQuantity =
    suggestedQuantities[extractGid(get('id', essentialsKitData))];

  const paintVariantQuantityMap = reduce(
    (mem, x) => {
      const forProduct = calculateVariantsForGallons(
        deNodify(get('variants', x)),
        get('quantity', x)
      );
      return merge(mem, forProduct);
    },
    {},
    mergedItems
  );

  const { suppliesInfo, recommendedSupplies } = useStaticQuery(graphql`
    query CalcResultsSuppliesContent {
      suppliesInfo: storyblokEntry(
        full_slug: { eq: "pages/calculator/results/recommendations-info" }
      ) {
        id
        name
        full_slug
        content
        field_title_string
        internalId
      }
      recommendedSupplies: storyblokEntry(
        full_slug: { eq: "pages/calculator/results/recommended-supplies" }
      ) {
        id
        name
        full_slug
        content
        field_title_string
        internalId
      }
    }
  `);

  // Storyblok Content
  const suppliesInfoBlok = useStoryblokState(suppliesInfo);
  const recommendedSuppliesBlok = useStoryblokState(recommendedSupplies);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      className="calc calc--step2 --controlled"
      id="calc--step2"
      flex={false}
    >
      <Box
        gap="medium"
        pad={
          isMobile
            ? undefined
            : { horizontal: '36px', bottom: '36px', top: 'small' }
        }
        className="cresults"
      >
        <Box direction="row-responsive" justify="stretch" align="stretch">
          {loading ? (
            <Box
              fill
              align="center"
              justify="center"
              pad="large"
              height={{ min: '100vh' }}
            >
              <Loading size="large" theme="light" centered dimension="180px" />
            </Box>
          ) : (
            <CalcResultsMainProducts
              genericImage={genericImage}
              productsData={data}
              loading={loading}
              paintData={paintData}
              onBackToForm={onBackToForm}
              preventAddToCartMessage={preventAddToCartMessage}
              activeDiscounts={activeDiscounts}
              industryReferralCode={referralCode}
              cartItems={cartItems}
              recommendedKitQuantity={recommendedKitQuantity}
              onBrowse={onBrowse}
              paintCollections={paintCollections}
            />
          )}
        </Box>

        <Box
          className="cresults__row"
          pad={isMobile ? 'large' : { top: 'medium' }}
          style={{ position: 'relative' }}
          gap="large"
          border={
            isMobile
              ? undefined
              : { side: 'top', size: 'small', color: 'black' }
          }
        >
          <Grid
            columns={{ count: isMobile ? 1 : 3, size: 'auto' }}
            gap="large"
            className="cresults__reco-grid"
          >
            <Box className="cresults__text-card">
              <Box margin={{ bottom: 'medium' }}>
                <Box
                  direction="row"
                  align="center"
                  pad={{ bottom: 'medium' }}
                  gap="1rem"
                  justify="start"
                >
                  <Group size="small" color="black" />
                  <Text>Number of people painting:</Text>
                  <InputIncDec
                    value={people}
                    onChange={(val) => {
                      dispatch(setPeople(val));
                    }}
                    disableInputEntry={true}
                    min={1}
                    max={3}
                  />
                </Box>
                <Heading
                  level={3}
                  size={isMobile ? 'medium' : 'small'}
                  margin={{ bottom: 'medium' }}
                >
                  {get('content.title', recommendedSuppliesBlok)}
                  <InfoToolTip
                    text={get(
                      'body',
                      head(get('content.body', suppliesInfoBlok))
                    )}
                    color="black"
                    size="24px"
                    lineHeight="30px"
                    style={{ padding: '0 5px' }}
                  />
                </Heading>

                <MarkdownContent>
                  {get(
                    'body',
                    head(get('content.body', recommendedSuppliesBlok))
                  )}
                </MarkdownContent>

                <Box margin={{ vertical: 'medium' }}>
                  <CalcProductQuantityList
                    quantities={suggestedQuantities}
                    itemData={[...recommendedToolsData, paintData || []]}
                    people={people}
                    cartItems={cartItems}
                  />
                </Box>
                <Box
                  flex={false}
                  className="buttonholder"
                  justify="start"
                  align="start"
                >
                  <ButtonAddAllToCart
                    primary
                    size="small"
                    quantityMap={relativeQuantities}
                    productData={supplyItems}
                    variantQuantityMap={paintVariantQuantityMap}
                  />
                </Box>
              </Box>
            </Box>
            {!isMobile &&
              keys(suggestedQuantities).length > 0 &&
              keys(suggestedQuantities).map((x) => {
                const match = find(
                  (z) => {
                    return isEqual(extractGid(get('id', z)), extractGid(x));
                  },
                  [...recommendedToolsData, essentialsKitData]
                );
                const isKit = isEqual(
                  extractGid(get('id', essentialsKitData)),
                  extractGid(get('id', match))
                );
                if (!match) return null;
                return (
                  <RecommendedToolCard
                    productData={match}
                    key={x}
                    supplyDiscount={
                      hasSuppliesDiscount
                        ? PAINT_AND_SUPPLY_DISCOUNT_PERCENTAGE
                        : 0
                    }
                    suggestedQuantity={relativeQuantities[extractGid(x)]}
                    hoverImageMatcher={isKit ? '_slide2' : 'hover'}
                    imageMatcher={isKit ? '_hero' : '_grid'}
                    suggestedQuantityFulfilled={
                      relativeQuantities[extractGid(x)] === 0
                    }
                  />
                );
              })}
          </Grid>
          {isMobile && (
            <Box
              width="100vw"
              className="reco-slider"
              margin={{ vertical: 'medium' }}
            >
              <ContentSlider
                params={{
                  slidesPerView: 1.2,
                  centeredSlides: true,
                  spaceBetween: 40,
                  navigation: true,
                }}
                navColor="000000"
                extraSlides={[
                  {
                    component: ShopCollectionButtonsSlide,
                  },
                ]}
              >
                {keys(suggestedQuantities).map((x) => {
                  const match = find(
                    (z) => {
                      return isEqual(extractGid(get('id', z)), extractGid(x));
                    },
                    [...recommendedToolsData, essentialsKitData]
                  );
                  const isKit = isEqual(
                    extractGid(get('id', essentialsKitData)),
                    extractGid(get('id', match))
                  );
                  if (!match) return null;
                  return (
                    <RecommendedToolCard
                      productData={match}
                      key={x}
                      supplyDiscount={
                        hasSuppliesDiscount
                          ? PAINT_AND_SUPPLY_DISCOUNT_PERCENTAGE
                          : 0
                      }
                      suggestedQuantity={relativeQuantities[extractGid(x)]}
                      hoverImageMatcher={isKit ? '_slide2' : 'hover'}
                      imageMatcher={isKit ? '_hero' : '_grid'}
                      suggestedQuantityFulfilled={
                        relativeQuantities[extractGid(x)] === 0
                      }
                    />
                  );
                })}
              </ContentSlider>
            </Box>
          )}
          {!isMobile && (
            <Box>
              <CartShopCollectionButtons
                fill
                justify="center"
                gap="medium"
                direction="row-responsive"
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

CalcResults.propTypes = {
  onBackToForm: PropTypes.func,
  productIds: PropTypes.array,
  onBrowse: PropTypes.func,
  paintCollections: PropTypes.array,
};

export default memo(CalcResults);
