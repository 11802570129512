import {
  get,
  map,
  isEqual,
  flatten,
  keys,
  multiply,
  find,
  sum,
} from '../nodash';
import extractGid from '../extractGid';
import deNodify from '../deNodify';

export const suggestionsTotal = ({ quantities, productsData }) => {
  const kitId = get('id', get('essentialsKit', productsData));
  const prices = map((id) => {
    const parsedId = extractGid(id);
    const dataSource = isEqual(parsedId, extractGid(kitId))
      ? [
          {
            ...get('essentialsKit', productsData),
            id: extractGid(get('essentialsKit.id', productsData)),
          },
        ]
      : get('paint', productsData);

    const variants = flatten(
      map((x) => deNodify(get('variants', x)), dataSource)
    );

    const maxPrice = parseFloat(
      get('priceV2.amount', find({ id }, variants)) || 59
    );
    return multiply(quantities[id], maxPrice);
  }, keys(quantities));

  return sum(map(parseFloat, prices));
};

export default suggestionsTotal;
